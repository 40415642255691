@tailwind base;
/* @layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }
  h2 {
    @apply text-3xl;
    @apply font-bold;
  }
  h3 {
    @apply text-2xl;
    @apply font-bold;
  }
  h4 {
    @apply text-xl;
    @apply font-semibold;
  }
  h5 {
    @apply text-lg;
    @apply font-semibold;
  }
  h6 {
    @apply text-sm;
    @apply font-semibold;
  }
} */
@layer base {
  body {
    @apply text-neutral-dark;
  }
}
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  /* background-color: #bfbfbf; */
  border-radius: 14px;
  /* border: 3px solid #123456; */
}

.dropdown-custom-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fafafa url('../assets/images/arrow_drop_down.png') no-repeat;
  background-position-x: 96%;
  background-position-y: 20px;
}

.dropdown-custom-select-outline {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fafafa url('../assets/images/expand_more.png') no-repeat;
  background-position-x: 92%;
  background-position-y: 16px;
  background-size: 16px;
}

#nprogress .bar {
  background: white !important;
  height: 4px !important;
}

.no-preflight h1 {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.5;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}
.no-preflight h2 {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.5;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}
.no-preflight h3 {
  font-size: 1.17em;
  font-weight: 700;
  line-height: 1.5;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.no-preflight h4 {
  font-size: 1em;
  font-weight: 700;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
}
.no-preflight h5 {
  font-size: 0.83em;
  font-weight: 700;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
}
.no-preflight h6 {
  font-size: 0.67em;
  font-weight: 700;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
}

.no-preflight p {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.5;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

.no-preflight a {
  color: #004cff;
}
.no-preflight:hover a {
  color: #5f84da;
}

.no-preflight ul {
  list-style-type: disc;
  padding-inline-start: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.no-preflight li {
  margin-bottom: 5px;
}

.no-preflight ol {
  list-style-type: decimal;
  padding-inline-start: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.no-preflight figcaption {
  z-index: 2;
  padding: 1em 0.5em;
  font-size: inherit;
  font-weight: 400;
  line-height: 1.5;
}

.no-preflight pre {
  background-color: #f9f9f9;
  word-wrap: break-word;
  white-space: pre-wrap !important;
  line-height: 1.45;
  color: #666;
  margin: 0 0 10px 0;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  padding: 8px;
}

.no-preflight blockquote {
  display: block;
  font-family: inherit;
  font-size: inherit;
  color: #999;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0 5px 0 20px;
  border: solid #b1b1b1;
  border-width: 0 0 0 5px;
}

.no-preflight figure {
  width: auto !important;
  height: 100% !important;
}

.no-preflight table {
  width: 100%;
}

.no-preflight td {
  border: #e1e1e1 solid 1px;
}

.no-preflight th {
  border: #e1e1e1 solid 1px;
  border-bottom: #333333 solid 1px;
  background-color: #f3f3f3;
  font-size: 16px;
  /* font-weight: bold; */
  text-align: center;
}

.no-preflight th > div > span {
  font-size: 16px;
  /* font-weight: bold; */
}

.no-preflight th > div {
  font-size: 16px;
  /* font-weight: bold; */
  font-family: Helvetica Neue;
}

.__se__float-left figure > img {
  float: left;
  margin-right: 4px;
}

.__se__float-left figure > img {
  float: left;
  margin-right: 4px;
}

.__se__float-right {
  float: right;
  margin-left: 4px;
}

.__se__float-center figure > img {
  margin: auto !important;
}

.__se__float-center figure {
  margin: auto !important;
}

.__se__float-none {
  float: none;
}

/* .slider-mobile {
  slicker-slide {
    max-width: 100%;
  }
} */
.slick-slider.my-slider {
  width: auto !important;
  opacity: 1 !important;
  transform: none !important;
}
/* table {
  border-collapse: inherit;
  width: 100%;
}

td {
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-left: none;
  border-right: none;
  border-collapse: collapse;
}

th {
  border-width: 1px;
  border-style: solid;
  border-color: blue;
  border-collapse: collapse;
} */
.rmsc.dark {
  --rmsc-border: #DCDEE0;
  --rmsc-gray: #555555;
  --rmsc-h: 58px;
}
